@import "../variables";

.appbox {
  position: relative;
  border-top: 1px solid #ddd;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05) inset;

  a {
    color: #333;
    display: block;
    font-size: 14px;
    padding: 20px 0 18px 55px;
    background: transparent none no-repeat 12px 12px;

    span {
      font-weight: bold;
    }
  }

  .qr {
    float: left;
    overflow: hidden;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 1000;
    width: 0;
    height: 0;

    opacity: 0;
    background-color: #fff;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-right: none;
  }

  &.available a:hover {
    background-color: #fff;
    margin-left: -4px;
    margin-right: -4px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding-left: 59px;

    .qr {
      display: block;
      opacity: 1;
      width: 201px;
      height: 202px;
      left: -202px;

      transition: opacity 0.2s ease-in-out;
    }
  }
}

.android {
  @extend .appbox;
  // background: #CBE86E;
  a {
    background-image: url("images/android-icon.png");
  }
}

.ios {
  @extend .appbox;
  a {
    background-image: url("images/ios.png");
  }
}

.wphone {
  @extend .appbox;
  a {
    background-image: url("images/winphone.png");
  }
}

.ubuntu {
  @extend .appbox;
  a {
    background-image: url("images/ubuntu.png");
  }
}

.favs,
.parsers {
  list-style: none;
  padding: 0;
  margin: 0;

  li a {
    width: auto;
    color: black;
    display: block;
    text-align: left;

    padding: 10px 0 9px 12px !important;
    border-top: 1px solid #ddd;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05) inset;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      background-color: #efefef;
      transition: background-color 0.2s ease-in-out;
    }

    & > .icon:first-child {
      margin-inline-end: 0.6em;
    }
  }
}

p.action {
  text-align: center;
}

.static_controller {
  img#compact {
    width: 300px;
  }

  img.illustrate {
    float: left;
    padding-right: 5px;
    padding-bottom: 5px;
  }
}

;@import "sass-embedded-legacy-load-done:76";