// Typography
$text-size: 62.5%
$text-color: #333
$text-font: $font-stack-system

$header-color: $text-color
$header-font: 'Droid Sans', $text-font
$header-bg-color: #f1f1f1

$frame-color: #ccc
$link-color: #2941B4

// Responsive
$responsive-mobile-width: 480px
$responsive-tablet-width: 767px
$responsive-default-width: 1350px

// Transition
%transition
  transition: all .2s ease-in-out


@import "sass-embedded-legacy-load-done:65"