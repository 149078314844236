@import "variables";

form {
  fieldset {
    margin: 1.4em 0 0 0;
    border: none;
    border-top: 1px solid $frame-color;

    > legend {
      padding: 0 5px;
      margin: 0 0 0 -5px;

      + p {
        margin-top: 0;
      }
    }
  }

  .mb-3 {
    margin: 8px 0;
  }

  p,
  .mb-3 {
    position: relative;
    padding: 0;

    label {
      display: block;
      font-weight: bold;

      & > .icon {
        margin-right: 0.4em;
      }
    }

    input[type="text"],
    input.string {
      width: 100%;
      max-width: 400px;

      margin: 4px 0;
      padding: 4px;
      border: 1px solid #ccc;
      border-radius: 2px;
      background-color: #fff;

      box-shadow: 0 0 3px rgba(#000, 0.2);
      @extend %transition;

      &:hover,
      &:focus {
        outline: none;
        border-color: #aaa;
        box-shadow: 0 0 3px rgba(#000, 0.4);
        @extend %transition;
      }

      &:focus {
        border-color: #777;
        @extend %transition;
      }

      &[readonly],
      &:disabled {
        box-shadow: none;
        border-color: #ccc;
        background-color: #fafafa;
        @extend %transition;
      }
    }

    input[type="checkbox"] {
      vertical-align: bottom;
      margin: 0 0.2em 0.1em 0;
      float: left;

      + label {
        // display: inline-block;
        margin-left: 20px;
      }
    }

    .error {
      input[type="text"] {
        box-shadow: 0 0 3px rgba(red, 0.4);

        &:hover,
        &:focus {
          box-shadow: 0 0 3px rgba(red, 0.6);
        }
      }

      .help-inline {
        color: red;
        display: block;
        font-style: italic;
        font-size: 0.85em;
        margin: 0 0 0 10px;

        &:before {
          content: "* ";
        }
      }
    }

    .invalid-feedback {
      color: red;
      display: block;
      font-style: italic;
      font-size: 0.85em;
      margin: 0 0 0 10px;

      &:before {
        content: "* ";
      }
    }

    input[type="submit"] {
      margin: 4px 0 4px 2px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:71";