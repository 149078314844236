@import "responsive";
@import "extends";

body {
  margin: 0;
  padding: 0;

  background-color: #ededed;
  background-image: url("images/fbg.png");
}

header,
section,
footer {
  display: block;
}

.fwrapper {
  @extend %clearfix;
}

.cwrapper {
  margin: 0 auto;
  max-width: 960px;
  padding: 0 15px;

  @include media(mobile) {
    padding: 0 11px;
  }
}

.clearfix {
  @extend %clearfix;
}

header ul.actions {
  list-style: none;
  float: right;
  margin: -2px 0 0 0;
  padding: 0.1em 0 0;

  li {
    float: left;
  }

  a {
    display: block;
    position: relative;
    padding: 0;
    margin-left: 0.4em;

    color: #bbb;
    font-size: 1.3em;
    line-height: 1em;
    text-align: center;
    text-shadow: none;

    @extend %transition;
    // @include transition(color .3s ease-in-out .2s);

    &:hover {
      color: #333;
      text-decoration: none;
      @extend %transition;
      // @include transition(color .1s ease-in-out);
    }

    span.avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
    }
  }
}

header#header {
  @extend .fwrapper;

  padding: 10px 0;
  background-image: url("images/hbg.png");
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

  @include media(mobile) {
    padding: 0;
  }

  h1 {
    background: transparent url("images/logo3.png") no-repeat 0 50%;
    text-shadow: 0 1px 1px #aaa;

    font-size: 230%;
    float: left;
    padding: 5px 0 5px 75px;
    margin: 0;
    min-height: 50px;

    @include media(mobile) {
      font-size: 150%;
      padding: 10px 0 10px 60px;
      background: transparent url("images/logo3.png") no-repeat -10px 50%;
    }

    @media only screen and (max-width: 340px) {
      padding: 15px 0 5px 50px;
      font-size: 100%;
    }

    a {
      color: #333;
      transition: text-shadow 0.8s ease-in-out;

      &:hover {
        text-shadow: 0 1px 8px rgba(#333, 0.8);
        text-decoration: none;
      }
    }

    img {
      float: right;
      position: absolute;
      top: -20px;
      margin-left: 40px;
      z-index: 2000;

      @include media(mobile) {
        top: -40px;
        right: -20px;
      }
    }
  }

  .actions {
    //margin-top: 6px;
    margin-right: 10px;

    a {
      font-size: 0.8em;
      vertical-align: middle;
      margin: 0 5px;
    }

    i {
      padding: 0.1em 0;
      font-size: 3.5rem;
      vertical-align: middle;
      display: block;
    }

    .account {
      float: left;
      margin-top: 7px;
      padding: 0 0.3em 0 1em;

      span {
        background-color: #f9f9f9;
        background-image: url("images/avatar.gif");
        background-repeat: no-repeat;
        background-position: 50% 50%;

        box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
        @extend %transition;
        // @include transition(all .3s ease-in-out .2s);
      }

      img,
      span {
        float: right;
        display: block;
        margin: 0 auto;
        height: 40px;
        width: 40px;
      }

      &:hover span {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        @extend %transition;
        // @include transition(all .2s ease-in-out);
      }
    }

    @include media(mobile) {
      margin-top: 6px;

      i {
        font-size: 1.6em;
      }

      .account {
        margin-top: 4px;
        padding-top: 0.2em;

        img,
        span {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}

#content {
  @extend .fwrapper;
  @extend .cwrapper;

  margin-bottom: 25px;

  .singelton {
    margin: 40px 0;
  }

  @include media(mobile) {
    margin-bottom: 2px;
    .singelton {
      margin: 0;
    }
  }

  section {
    margin: 14px 0 0 0;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

    @include media(handheld) {
      margin: 4px 0 0 0;
    }

    header {
      @extend %clearfix;
      padding: 4px 15px 3px 15px;

      @include media(mobile) {
        padding: 2px 6px 2px 6px;
      }

      border-top: 1px solid #ddd;
      text-shadow: 0 1px 1px #aaa;
      background-image: url("images/hbg.png");
      // @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.05) inset);

      ~ .content {
        border-top: 1px solid #ddd;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05) inset;
      }

      h3,
      h4,
      h5 {
        margin: 4px 0;
      }

      ul.actions {
        list-style: none;
        float: right;
        margin: -2px 0 0 0;

        li {
          float: left;
        }

        a {
          &:hover {
            color: #333;
          }
        }

        @include media(mobile) {
          padding: 0;
        }
      }
    }

    .content {
      padding: 1px 15px 2px 15px;

      h2,
      h3,
      h4,
      h5 {
        margin-top: 16px;
      }

      @include media(mobile) {
        padding: 2px 6px 2px 6px;

        h2,
        h3,
        h4,
        h5 {
          margin-top: 6px;
          font-weight: bold;
        }
      }

      // > p:first-child { margin-top: 0; }
      // > p:last-child { margin-bottom: 0; }

      .add_notice {
        margin: 0 3px;
        padding: 16px 10px;
        text-align: center;

        background: transparent url("images/add_notice.png") no-repeat top right;
      }
    }

    ul.content {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        padding: 6px 15px 6px 15px;

        &:not(:first-child) {
          border-top: 1px solid #ddd;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05) inset;
        }
      }

      &.nav {
        li {
          padding: 0;
        }
        a {
          display: block;
          padding: 12px 15px 10px 35px;
          color: #333;
          font-weight: bold;
          @extend %transition;
          // @include transition(background-color .2s ease-in-out);

          &:hover {
            background-color: #efefef;
            @extend %transition;
            // @include transition(background-color .2s ease-in-out);
          }
        }
      }
    }

    .map {
      border-top: 1px solid #ccc;
    }
  }

  aside {
    section .content {
      padding: 0px 5px 1px;
    }
  }
}

footer#footer {
  color: #aaa;
  text-align: left;
  text-shadow: 0 1px 1px #fff;
  margin-top: 10px;
  font-size: 11px;
  font-size: 1.1rem;

  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }

  li {
    margin-right: 0.5em;

    &.revision {
      flex-grow: 1;
      text-align: right;
      margin: 0;
    }
  }

  li:not(:first-child):not(.revision):before {
    content: "·";
    margin-right: 0.5em;
  }

  a {
    color: #aaa;
    &:hover {
      color: #555;
    }
  }
}

;@import "sass-embedded-legacy-load-done:66";