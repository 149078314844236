@import "responsive";
@import "variables";

$alert: #333 !default;
$alert-bg: lighten(#ccc, 19%) !default;
$alert-border: darken(#ccc, 10%) !default;
$alert-error: #b94a48 !default;
$alert-error-bg: #f2dede !default;
$alert-error-border: darken($alert-error-bg, 20%) !default;
$alert-warning: #c09853 !default;
$alert-warning-bg: #fcf8e3 !default;
$alert-warning-border: darken($alert-warning-bg, 30%) !default;
$alert-notice: darken(#468847, 15%) !default;
$alert-notice-bg: #dff0d8 !default;
$alert-notice-border: darken($alert-notice-bg, 30%) !default;

@mixin alert($color, $bg, $border) {
  color: $color;
  padding: 10px 32px 10px 14px;
  background-color: $bg;
  border: 1px solid $border;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin: 14px 0 0;

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }

  > a.close {
    content: "×";
    float: right;
    position: absolute;
    top: 0;
    right: 0;

    display: block;
    color: #aaa;
    font-size: 20px;
    padding: 3px 12px;
    font-weight: bold;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }

  @include media(handheld) {
    margin: 3px 0;
    padding: 4px 29px 4px 6px;
  }
}

.alert {
  @include alert($alert, $alert-bg, $alert-border);
}
.alert-error {
  @include alert($alert-error, $alert-error-bg, $alert-error-border);
}
.alert-notice,
.alert-success {
  @include alert($alert-notice, $alert-notice-bg, $alert-notice-border);
}
.alert-warning {
  @include alert($alert-warning, $alert-warning-bg, $alert-warning-border);
}

;@import "sass-embedded-legacy-load-done:69";