@import "../responsive";
@import "../extends";

#content {
  #feedback_message {
    width: 100%;
    height: 5em;
  }
  #canteen-header {
    padding: 95px 0 35px;
    background-color: #fff;
    background-image: url("images/canteen.jpg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    h1 {
      color: #fff;
      margin: 0;
      padding: 15px 25px;
      text-shadow: none;
      background-color: rgba(0, 0, 0, 0.8);

      .fav {
        float: right;
        color: white;
        padding: 0 7px;
        border-radius: 20px;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #000;
          text-decoration: none;
          background-color: #ffffff;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    @include media(tablet) {
      padding: 0;
      background-image: none;
    }

    @include media(mobile) {
      padding: 0;
      background-image: none;

      h1 {
        margin: 0;
        padding: 3px 4px;
        text-align: center;
      }
    }

    span.outoforder {
      color: $alert-error;
    }
  }
}

.canteens_controller,
.menu_controller {
  .page-header {
    @extend %clearfix;

    @include media(handheld) {
      h2 {
        margin-top: 7px;
        font-size: 18px;
        font-size: 1.8rem;
      }
    }

    a.next,
    a.prev {
      display: block;
      margin: 3px 0 0;
      padding: 10px;

      @include media(handheld) {
        margin: 0;
      }

      span {
        width: 14px;
        height: 14px;
        display: block;
        transform: rotate(-45deg);
        @extend %hover-transition;
      }

      &.next {
        float: right;

        span {
          border-bottom: 3px solid #ccc;
          border-right: 3px solid #ccc;
        }
      }

      &.prev {
        float: left;

        span {
          border-top: 3px solid #ccc;
          border-left: 3px solid #ccc;
        }
      }

      &:hover span {
        border-color: #333;
        background-color: rgba(0, 0, 0, 0.05);
        @extend %hover-transition;
      }
    }
  }

  ul.meals {
    margin: 0;
    padding: 0;
    // padding: 0 0 5px;
    list-style: none;

    > li {
      border-top: 1px solid #e5e5e5;
      // padding: 4px 15px;
      @extend %clearfix;

      @include media(mobile) {
        padding: 1px 7px;
      }

      &:first-child {
        padding-top: 5px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05) inset;
      }
      // &:last-child > div { padding-bottom: 8px; }

      > h3 {
        float: left;
        width: 140px;
        font-size: 13px;
        font-size: 1.3rem;
        font-weight: bold;
        text-align: right;
        margin: 9px 0;

        @include media(mobile) {
          float: none;
          width: 100%;
          text-align: left;
          margin: 5px 0 5px;
        }
      }
      > p,
      > ul > li {
        margin: 9px 0;
        padding: 0 0 0 160px;

        @include media(mobile) {
          margin: 5px 0;
          padding: 0;
        }
      }

      > ul {
        list-style: none;
        padding: 0;

        > li {
          @extend %clearfix;
          border-bottom: 1px dotted #eee;
          padding-right: 10px;

          &:last-child {
            border: none;
          }

          p {
            margin-bottom: 2px;
          }

          ul.notes,
          ul.prices {
            margin: 0;
            list-style: none;
            position: relative;
            display: table-cell;
            padding: 0 0 12px 0;
            @extend %hover-transition;

            color: #ccc;
            font-size: 0.85em;
          }

          ul.notes {
            margin: 0;

            li {
              float: left;
              margin: 0 0.5em 0 0;
              &:before {
                content: "~";
              }
            }
          }

          ul.prices {
            float: right;
            margin: 0 0 0 15px;
            overflow: hidden;

            li {
              float: left;
              margin: 0 0 0 5px;
              width: 55px;

              opacity: 0;
              visibility: hidden;
              @extend %hover-transition;

              span {
                @extend %hover-transition;
                color: #aaa;
                bottom: 0;
                opacity: 0;
                position: absolute;
                visibility: hidden;
              }

              &:last-child {
                opacity: 1;
                visibility: visible;
              }
            }
          }

          &:hover,
          &.active {
            ul {
              color: #666;
              @extend %hover-transition;
            }
            ul.prices li,
            ul.prices span {
              visibility: visible;
              opacity: 1;
              @extend %hover-transition;
            }
          }

          @include media(mobile) {
            padding-bottom: 4px;

            ul.notes,
            ul.prices {
              margin: 1px 0 0 0;
              color: #aaa;
              padding-bottom: 0;
            }
            ul.prices li {
              width: auto;
              margin-left: 1em;
              visibility: visible;
              opacity: 1;
            }
            ul.prices li span {
              display: none;
            }
          }
        }
      }
    }
  }

  .content .map {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  }

  .status p {
    padding: 5px;
  }
}

;@import "sass-embedded-legacy-load-done:75";