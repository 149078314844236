@import "responsive";
@import "variables";

.leaflet-container {
  .leaflet-control a:hover {
    text-decoration: none;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 2px;
    font-size: 1rem;
  }

  .marker-cluster {
    background-clip: padding-box;
    border-radius: 50%;

    background-color: #4598d0;
    background-color: rgba(#4598d0, 0.9);
    background-image: linear-gradient(rgba(#4598d0, 0.9), rgba(#1374cf, 0.9));
    border: 1px solid #597393;
    border: 1px solid rgba(#597393, 0.9);

    div {
      width: 28px;
      height: 28px;
      margin: 5px;

      border-radius: 50%;

      text-align: center;
      vertical-align: middle;

      font-weight: bold;
      background-color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;

      > span {
        font-size: 1.2rem;
      }
    }
  }
}

.map {
  width: 100%;
  height: 250px;
}

#index_map {
  .index-map {
    background-color: transparent;

    input {
      height: 52px;
      padding: 6px 15px;
      border: none;
      margin: 0;
      width: 100%;
      font-size: 2em;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05) inset;
      @extend %transition;

      &:hover,
      &:focus {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) inset;
        @extend %transition;
      }
    }

    .map {
      height: 448px;
      height: 500px;
    }
  }

  @include media(handheld) {
    .map {
      height: 350px;
    }

    .favorites {
      width: 100%;
      border-bottom: none;

      &.empty {
        display: none;
        visibility: hidden;
      }
    }
  }

  @include media(mobile) {
    .map {
      height: 250px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:74";