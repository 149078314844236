@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%hover-transition {
  @extend %transition;
}

%clearfix {
  @include clearfix;
}

;@import "sass-embedded-legacy-load-done:67";