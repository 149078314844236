.feed {
  border: 1px solid #555;
  margin: 5px -7px;
  padding: 0px 7px;
}

.parsers_controller,
.feeds_controller {
  th.number,
  td.number {
    text-align: center;
  }
}

;@import "sass-embedded-legacy-load-done:78";