table {
  @extend p;
  width: 100%;
  border-collapse: collapse;

  tr {
    td {
      border-top: 1px solid #eee;
      padding: 2px 4px;

      @extend %transition;
    }
    &:first-child td {
      border: none;
    }

    td.actions {
      text-align: right;
      padding: 0 4px;

      a {
        color: #eee;
        display: block;
        padding: 0 2px;
        overflow: hidden;
        height: 20px;

        i:before {
          font-size: 20px;
        }

        @extend %transition;

        &:hover {
          text-decoration: none;
          color: #333 !important;

          @extend %transition;
        }
      }
    }

    &:hover {
      td {
        background-color: #f8f8ff;

        &.actions a {
          color: #aaa;
          @extend %transition;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:72";