// jquery.autocomplete

.autocomplete-w1 {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 6px 0 0 6px;
}

.autocomplete {
  border: 1px solid #999;
  background: #fff;
  cursor: default;
  text-align: left;
  max-height: 350px;
  overflow: auto;
  margin: -6px 6px 6px -6px;
}
.autocomplete .selected {
  background: #f0f0f0;
}
.autocomplete div {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}
.autocomplete strong {
  font-weight: normal;
  color: #3399ff;
}

;@import "sass-embedded-legacy-load-done:73";