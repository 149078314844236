@import "../responsive";
@import "../extends";

.users_controller {
  .identities {
    @extend %clearfix;
    padding: 3px 3px 4px !important;

    p {
      clear: both;
      padding: 6px 12px 0;
    }
  }

  .identity {
    float: left;
    width: 48%;
    height: 74px;
    margin: 6px;
    padding: 0 14px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(#000, 0.1);

    @include media(mobile) {
      width: 98%;
    }

    i {
      color: #444;
      float: left;
      font-size: 45px;
      margin-top: 13px;
    }

    span.provider {
      display: block;
      margin: 20px 0 0 55px;
      font-size: 11px;
      font-size: 1.1rem;
      color: #999;
    }

    span.uid {
      display: block;
      margin: 0 0 0 55px;
    }

    a.destroy {
      position: absolute;
      top: 0;
      right: 0;

      color: #aaa;
      display: block;
      font-weight: bold;
      font-size: 20px;
      padding: 2px 10px 0 8px;

      &:hover {
        color: #333;
        text-decoration: none;
      }
    }

    &.add_notice {
      float: right;
      text-align: right !important;
      border: 0;
      margin-right: 10px !important;
      height: 84px;
      box-shadow: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:77";